<!-- 包含POINT集计 -->
<template>
  <div>
    <div class="nav-title">
      {{ $t("statistic") }}
    </div>

    <div class="search-query-container">
      <div class="search-query" style="min-width: 260px;">
        <el-form :form="searchForm">
          <el-form-item :label="$t('enterpriseCode')">
            <el-input clearable v-model="searchForm.entName"></el-input>
          </el-form-item>

          <el-form-item :label="$t('statisticTimeRange')">
            <search-date class="search-date" :data.sync="searchForm.searchDate" :format="format" style="width: 100%;" />
          </el-form-item>
        </el-form>

        <div class="search-btn">
          <el-button class="confirm" @click="getList(1)" :disabled="loading">{{ $t("search") }}</el-button>
        </div>
      </div>

      <div class="summary-container">
        <div class="summary-title">
          {{ $t("totalTradeOrder") }}
        </div>
        <div class="summary-title">
          <div class="name-value statistic-summary-table">
            <table cellspacing="0" cellpadding="0" border="0">
              <tr>
                <td class="name">{{ $t("statisticTotalCount") }}</td>
                <td class="value">
                  {{ numFormat(totalCount) }}
                </td>
              </tr>
              <tr>
                <td class="name">{{ $t("statisticPayCount") }}</td>
                <td class="value">
                  {{ numFormat(payCount) }}
                </td>
              </tr>
              <tr>
                <td class="name">{{ $t("statisticRefundCount") }}</td>
                <td class="value">
                  {{ numFormat(refundCount) }}
                </td>
              </tr>
            </table>
          </div>

          <div class="name-value statistic-summary-table">
            <table cellspacing="0" cellpadding="0" border="0">
              <tr>
                <td class="name">{{ $t("statisticTotalAmount") }}</td>
                <td class="value">
                  {{ moneyFormat(totalAmount) }}
                </td>
              </tr>

              <tr>
                <td class="name">{{ $t("statisticPayAmount") }}</td>
                <td class="value">
                  {{ moneyFormat(payAmount) }}
                </td>
              </tr>

              <tr>
                <td class="name">{{ $t("statisticRefundAmount") }}</td>
                <td class="value">
                  {{ moneyFormat(refundAmount) }}
                </td>
              </tr>
            </table>
          </div>
        </div>
        <div class="summary-title">
          {{ $t("totalPspTransactions") }}
        </div>
        <div class="summary-title">
          <div class="name-value statistic-summary-table">
            <table cellspacing="0" cellpadding="0" border="0">
              <tr>
                <td class="name">{{ $t("pspTradeRefundTotalCount") }}</td>
                <td class="value">
                  {{ numFormat(totalPspCount) }}
                </td>
              </tr>
            </table>
          </div>

          <div class="name-value statistic-summary-table">
            <table cellspacing="0" cellpadding="0" border="0">
              <tr>
                <td class="name">{{ $t("totalAmount") }}</td>
                <td class="value">
                  {{ moneyFormat(totalPspAmount) }}
                </td>
              </tr>
            </table>
          </div>
        </div>
        <div class="summary-title">
          {{ $t("totalPointTransactions") }}
        </div>
        <div class="summary-title">
          <div class="name-value statistic-summary-table">
            <table cellspacing="0" cellpadding="0" border="0">
              <tr>
                <td class="name">{{ $t("tradePointUseCount") }}</td>
                <td class="value">
                  {{ numFormat(totalPointCount) }}
                </td>
              </tr>
              <tr>
                <td class="name">{{ $t("tradePointGiveCount") }}</td>
                <td class="value">
                  {{ numFormat(totalPointGiveCancelCount) }}
                </td>
              </tr>
            </table>
          </div>

          <div class="name-value statistic-summary-table">
            <table cellspacing="0" cellpadding="0" border="0">
              <tr>
                <td class="name">{{ $t("tradePointUseAmount") }}</td>
                <td class="value">
                  {{ pointFormat(totalPointAmount) }}
                </td>
              </tr>
              <tr>
                <td class="name">{{ $t("tradePointGiveAmount") }}</td>
                <td class="value">
                  {{ pointFormat(totalPointGiveCancelAmount) }}
                </td>
              </tr>
            </table>
          </div>
        </div>
        <div class="summary-title">
          {{ $t("totalExtTransactions") }}
        </div>
        <div class="summary-title">
          <div class="name-value statistic-summary-table">
            <table cellspacing="0" cellpadding="0" border="0">
              <tr>
                <td class="name">{{ $t("totalExtTradeRefundCount") }}</td>
                <td class="value">
                  {{ numFormat(totalExtCount) }}
                </td>
              </tr>
            </table>
          </div>

          <div class="name-value statistic-summary-table">
            <table cellspacing="0" cellpadding="0" border="0">
              <tr>
                <td class="name">{{ $t("totalAmount") }}</td>
                <td class="value">
                  {{ moneyFormat(totalExtAmount) }}
                </td>
              </tr>
            </table>
          </div>
        </div>
      </div>
    </div>

    <div class="result-table statistics-table">
      <el-tabs v-model="tabType" @tab-click="tabClick">
        <el-tab-pane :label="$t('listByDate')" name="1">
          <el-table :data="statMonRecordList" v-loading="loading">
            <el-table-column :label="$t('date')">
              <template slot-scope="scope">
                {{ dateFormat(scope.row.tradeDate, "YYYY/MM/DD") }}
              </template>
            </el-table-column>
            <el-table-column :label="$t('listColTotalCount')">
              <template slot-scope="scope">
                {{ numFormat(scope.row.totalPspCount) }}
              </template>
            </el-table-column>
            <el-table-column :label="$t('listColTotalAmount')">
              <template slot-scope="scope">
                {{ moneyFormat(scope.row.totalPspAmount) }}
              </template>
            </el-table-column>
            <el-table-column :label="$t('listColPayCount')">
              <template slot-scope="scope">
                {{ numFormat(scope.row.pspPayCount) }}
              </template>
            </el-table-column>
            <el-table-column :label="$t('listColPayAmount')">
              <template slot-scope="scope">
                {{ moneyFormat(scope.row.pspPayAmount) }}
              </template>
            </el-table-column>
            <el-table-column :label="$t('listColRefundCount')">
              <template slot-scope="scope">
                {{ numFormat(scope.row.pspRefundCount) }}
              </template>
            </el-table-column>
            <el-table-column :label="$t('listColRefundAmount')">
              <template slot-scope="scope">
                {{ moneyFormat(scope.row.pspRefundAmount) }}
              </template>
            </el-table-column>
            <el-table-column :label="$t('tradePointUseCount')">
              <template slot-scope="scope">
                {{ numFormat(scope.row.totalPointCount) }}
              </template>
            </el-table-column>
            <el-table-column :label="$t('tradePointUseAmount')">
              <template slot-scope="scope">
                {{ pointFormat(scope.row.totalPointAmount) }}
              </template>
            </el-table-column>
            <el-table-column :label="$t('tradePointGiveCount')">
              <template slot-scope="scope">
                {{ numFormat(scope.row.totalPointGiveCancelCount) }}
              </template>
            </el-table-column>
            <el-table-column :label="$t('tradePointGiveAmount')">
              <template slot-scope="scope">
                {{ pointFormat(scope.row.totalPointGiveCancelAmount) }}
              </template>
            </el-table-column>
            <el-table-column :label="$t('totalExtCount')">
              <template slot-scope="scope">
                {{ numFormat(scope.row.totalExtCount) }}
              </template>
            </el-table-column>
            <el-table-column :label="$t('totalExtAmount')">
              <template slot-scope="scope">
                {{ moneyFormat(scope.row.totalExtAmount) }}
              </template>
            </el-table-column>
          </el-table>
        </el-tab-pane>
        <el-tab-pane :label="$t('listByCode')" name="0">
          <!-- 按照店铺Code统计 -->
          <el-table :data="statShopRecordList" v-loading="loading" v-if="statShopRecordList">
            <el-table-column prop="storeId" :label="$t('storeCode')"> </el-table-column>
            <el-table-column prop="storeName" :label="$t('storeName')"> </el-table-column>
            <el-table-column :label="$t('listColTotalCount')">
              <template slot-scope="scope">
                {{ numFormat(scope.row.totalPspCount) }}
              </template>
            </el-table-column>
            <el-table-column :label="$t('listColTotalAmount')">
              <template slot-scope="scope">
                {{ moneyFormat(scope.row.totalPspAmount) }}
              </template>
            </el-table-column>
            <el-table-column :label="$t('listColPayCount')">
              <template slot-scope="scope">
                {{ numFormat(scope.row.pspPayCount) }}
              </template>
            </el-table-column>
            <el-table-column :label="$t('listColPayAmount')">
              <template slot-scope="scope">
                {{ moneyFormat(scope.row.pspPayAmount) }}
              </template>
            </el-table-column>
            <el-table-column :label="$t('listColRefundCount')">
              <template slot-scope="scope">
                {{ numFormat(scope.row.pspRefundCount) }}
              </template>
            </el-table-column>
            <el-table-column :label="$t('listColRefundAmount')">
              <template slot-scope="scope">
                {{ moneyFormat(scope.row.pspRefundAmount) }}
              </template>
            </el-table-column>
            <el-table-column :label="$t('tradePointUseCount')">
              <template slot-scope="scope">
                {{ numFormat(scope.row.totalPointCount) }}
              </template>
            </el-table-column>
            <el-table-column :label="$t('tradePointUseAmount')">
              <template slot-scope="scope">
                {{ pointFormat(scope.row.totalPointAmount) }}
              </template>
            </el-table-column>
            <el-table-column :label="$t('tradePointGiveCount')">
              <template slot-scope="scope">
                {{ numFormat(scope.row.totalPointGiveCancelCount) }}
              </template>
            </el-table-column>
            <el-table-column :label="$t('tradePointGiveAmount')">
              <template slot-scope="scope">
                {{ pointFormat(scope.row.totalPointGiveCancelAmount) }}
              </template>
            </el-table-column>
            <el-table-column :label="$t('totalExtCount')">
              <template slot-scope="scope">
                {{ numFormat(scope.row.totalExtCount) }}
              </template>
            </el-table-column>
            <el-table-column :label="$t('totalExtAmount')">
              <template slot-scope="scope">
                {{ moneyFormat(scope.row.totalExtAmount) }}
              </template>
            </el-table-column>
          </el-table>
          <!-- 按照子企业Code统计 -->
          <el-table :data="statEntRecordList" v-loading="loading" v-else>
            <el-table-column prop="entName" :label="$t('enterpriseCode')" width="300"> </el-table-column>
            <el-table-column prop="entInfo" :label="$t('enterpriseName')" width="300"> </el-table-column>
            <el-table-column :label="$t('listColTotalCount')">
              <template slot-scope="scope">
                {{ numFormat(scope.row.totalPspCount) }}
              </template>
            </el-table-column>
            <el-table-column :label="$t('listColTotalAmount')">
              <template slot-scope="scope">
                {{ moneyFormat(scope.row.totalPspAmount) }}
              </template>
            </el-table-column>
            <el-table-column :label="$t('listColPayCount')">
              <template slot-scope="scope">
                {{ numFormat(scope.row.pspPayCount) }}
              </template>
            </el-table-column>
            <el-table-column :label="$t('listColPayAmount')">
              <template slot-scope="scope">
                {{ moneyFormat(scope.row.pspPayAmount) }}
              </template>
            </el-table-column>
            <el-table-column :label="$t('listColRefundCount')">
              <template slot-scope="scope">
                {{ numFormat(scope.row.pspRefundCount) }}
              </template>
            </el-table-column>
            <el-table-column :label="$t('listColRefundAmount')">
              <template slot-scope="scope">
                {{ moneyFormat(scope.row.pspRefundAmount) }}
              </template>
            </el-table-column>
            <el-table-column :label="$t('tradePointUseCount')">
              <template slot-scope="scope">
                {{ numFormat(scope.row.totalPointCount) }}
              </template>
            </el-table-column>
            <el-table-column :label="$t('tradePointUseAmount')">
              <template slot-scope="scope">
                {{ pointFormat(scope.row.totalPointAmount) }}
              </template>
            </el-table-column>
            <el-table-column :label="$t('tradePointGiveCount')">
              <template slot-scope="scope">
                {{ numFormat(scope.row.totalPointGiveCancelCount) }}
              </template>
            </el-table-column>
            <el-table-column :label="$t('tradePointGiveAmount')">
              <template slot-scope="scope">
                {{ pointFormat(scope.row.totalPointGiveCancelAmount) }}
              </template>
            </el-table-column>
            <el-table-column :label="$t('totalExtCount')">
              <template slot-scope="scope">
                {{ numFormat(scope.row.totalExtCount) }}
              </template>
            </el-table-column>
            <el-table-column :label="$t('totalExtAmount')">
              <template slot-scope="scope">
                {{ moneyFormat(scope.row.totalExtAmount) }}
              </template>
            </el-table-column>
          </el-table>
        </el-tab-pane>
      </el-tabs>

      <el-button
        class="cancel statistics-download-btn"
        v-loading="downloadLoading"
        :disabled="downloadLoading"
        @click="downloadClick"
        v-permission="'Stat.StatDownload'"
        icon="el-icon-download"
      >
        {{ $t("dataDownload") }}
      </el-button>

      <el-pagination
        background
        layout=" prev, pager, next, sizes, total"
        :total="total"
        :page-size="currentPageSize"
        :page-sizes="[10, 15, 20]"
        :current-page="currentPage"
        @current-change="currentChange"
        @size-change="sizeChange"
        class="common-pagination"
      >
      </el-pagination>
    </div>

    <modal-dialog :show-dialog.sync="downloadDialog" :dialog-title="$t('dataDownload')" :show-footer="false">
      <div class="download-type-container" v-loading="downloadLoading">
        <div class="download-type-item" @click="downloadData(0)">
          <img src="~@/assets/download_type_icon/excel.svg" alt="" />
          <span>Excel</span>
        </div>

        <div class="download-type-item" @click="downloadData(1)">
          <img src="~@/assets/download_type_icon/pdf.svg" alt="" />
          <span>PDF</span>
        </div>

        <div class="download-type-item" @click="downloadData(2)">
          <img src="~@/assets/download_type_icon/zip.svg" alt="" />
          <span>ZIP</span>
        </div>
      </div>
    </modal-dialog>
  </div>
</template>

<script>
import { getSessionStorage } from "@/utils/storage";
import { statList, downloadStatFile } from "api/statistics";
import { moneyFormat, numFormat, pointFormat } from "utils";
import { dateFormat, diffDays } from "utils/date";
import pagination from "@/mixins/pagination";
import { executeDownload } from "utils/download";

export default {
  name: "Statistics",
  mixins: [pagination],
  data() {
    return {
      searchForm: {
        searchDate: [
          this.moment()
            .startOf("month")
            .format("YYYY/MM/DD"),
          this.moment()
            .endOf("day")
            .format("YYYY/MM/DD")
        ],
        entName: "",
        statType: 0 //集計类型 0 コード別で表示 1 日付順で表示
      },
      format: "yyyy/MM/dd",
      tabType: "1",
      loading: false,
      downloadLoading: false,
      statShopRecordList: [],
      statEntRecordList: [],
      statMonRecordList: [],
      totalCount: 0,
      totalAmount: 0,
      payCount: 0,
      payAmount: 0,
      refundCount: 0,
      refundAmount: 0,
      totalPspCount: 0,
      totalPspAmount: 0,
      totalPointCount: 0,
      totalPointAmount: 0,
      totalPointGiveCancelCount: 0,
      totalPointGiveCancelAmount: 0,
      totalExtCount: 0,
      totalExtAmount: 0,
      userInfo: {},
      options: {},
      downloadDialog: false
    };
  },
  methods: {
    numFormat,
    moneyFormat,
    pointFormat,
    dateFormat,
    tabClick() {
      this.getList(1);
    },

    getList(pageIndex = 1, pageSize = this.currentPageSize) {
      this.statShopRecordList = [];
      this.statEntRecordList = [];
      this.statMonRecordList = [];
      this.currentPage = pageIndex;
      let beginTime = this.searchForm.searchDate[0];
      let endTime = this.searchForm.searchDate[1];
      if (diffDays(endTime, beginTime) > 31) {
        this.$message.warning(this.$t("statisticSearchPeriodLimit"));
        return;
      }
      this.loading = true;

      statList({
        pageIndex,
        pageSize,
        beginTime,
        endTime,
        entName: this.searchForm.entName,
        statType: parseInt(this.tabType)
      }).then(response => {
        this.loading = false;
        if (response.statusCode === 200 && response.resultStatus === "Success") {
          this.totalCount = response.result.totalCount;
          this.totalAmount = response.result.totalAmount;
          this.payCount = response.result.payCount;
          this.payAmount = response.result.payAmount;
          this.refundCount = response.result.refundCount;
          this.refundAmount = response.result.refundAmount;
          this.totalPspCount = response.result.totalPspCount;
          this.totalPspAmount = response.result.totalPspAmount;
          this.totalPointCount = response.result.totalPointCount;
          this.totalPointAmount = response.result.totalPointAmount;
          this.totalPointGiveCancelCount = response.result.totalPointGiveCancelCount;
          this.totalPointGiveCancelAmount = response.result.totalPointGiveCancelAmount;
          this.totalExtCount = response.result.totalExtCount;
          this.totalExtCount = response.result.totalExtCount;
          this.statShopRecordList = response.result.statShopRecordList;
          this.statEntRecordList = response.result.statEntRecordList;
          this.statMonRecordList = response.result.statMonRecordList;
          this.total = response.totalCount;
        }
      });
    },

    downloadData(fileType) {
      this.downloadDialog = false;
      let beginTime = this.searchForm.searchDate[0];
      let endTime = this.searchForm.searchDate[1];
      this.downloadLoading = true;
      downloadStatFile({
        beginTime,
        endTime,
        EntName: this.searchForm.entName,
        StatType: this.tabType,
        FileFormat: fileType
      }).then(response => {
        if (response.data) {
          executeDownload(response);
          this.downloadLoading = false;
          this.$message.success(this.$t("success"));
        } else {
          this.downloadLoading = false;
        }
      });
    },

    downloadClick() {
      this.downloadDialog = true;
    }
  },

  mounted() {
    this.userInfo = JSON.parse(getSessionStorage("userInfo"));
    this.searchForm.entName = this.userInfo.entName;
    // this.getList();
  }
};
</script>

<style lang="scss" scoped>
@import "~@/styles/mixin.scss";
@import "~@/styles/variables.scss";

.statistic-summary-table {
  display: inline-block;
  max-width: 450px;
  margin-right: 10px;
  &:last-child {
    margin-right: 0;
  }
}

.statistics-table {
  margin-top: 20px;
  position: relative;

  ::v-deep .el-tabs__nav-wrap::after {
    width: calc(100% - 246px);
  }

  .statistics-download-btn {
    position: absolute;
    right: 1px;
    top: -2px;
  }
}
</style>
